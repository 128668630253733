// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")
require("trix")
require("@rails/actiontext")
require('webpack-jquery-ui');
require('webpack-jquery-ui/css');
require('select2/dist/js/select2.full');
require('datatables.net-bs4')

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import $ from 'jquery';
global. $ = $
global.jQuery = $
import 'bootstrap/dist/js/bootstrap';
import 'jquery.tagsinput-revisited/src/jquery.tagsinput-revisited';
import 'jquery.maskedinput/src/jquery.maskedinput';
import './rSlider';

$(document).on('turbolinks:load', function() {
    $('body').tooltip({
        selector: '[data-toggle="tooltip"]',
        container: 'body',
    });

    $('body').popover({
        selector: '[data-toggle="popover"]',
        container: 'body',
        html: true,
        trigger: 'hover',
    });

    $(".select-header-сity").select2({
        "language": {
            "noResults": function(){
                return "<small'>Не нашли нужный населенный пункт? Позвоните нам по номеру " +
                    "<span class='font-weight-bold'>8-800-100-32-72</span> и мы поможем Вам</small>";
            }
        },
        escapeMarkup: function (markup) {
            return markup;
        }
    });

    $("#select-header-сity-mobile").select2({
        "language": {
            "noResults": function(){
                return "<small'>Не нашли нужный населенный пункт? Позвоните нам по номеру " +
                    "<span class='font-weight-bold'>8-800-100-32-72</span> и мы поможем Вам</small>";
            }
        },
        escapeMarkup: function (markup) {
            return markup;
        }
    });
});


//= require data-confirm-modal// Support component names relative to this directory:
// var componentRequireContext = require.context("components", true);
// var ReactRailsUJS = require("react_ujs");
// ReactRailsUJS.useContext(componentRequireContext);
